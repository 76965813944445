import { Card, PrimaryButton, SecondaryButton } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

export default function Register({ onNext = () => {}, show = false }) {
  const [isAwnserd, setIsAwnserd] = useState(false);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    home_site: "",
    // rules: false,
  });

  const entries = {
    // firstname: "entry.894840064",
    // lastname: "entry.23773172",
    home_site: "entry.647379411",
  };

  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  useEffect(() => {
    if (
      // user.firstname.length >= 1 &&
      // user.lastname.length >= 2 &&
      user.home_site.length >= 2
      // &&
      // user.rules
    ) {
      setIsAwnserd(true);
    } else {
      setIsAwnserd(false);
    }
  }, [user]);

  return (
    <div
      className={joinClasses(
        "flex justify-between w-full w-full",
        show ? "block" : "hidden"
      )}
    >
      <div
        className="w-full bg-no-repeat bg-center"
        // style={{
        //   backgroundImage: `url("/assets/questions/1-2.svg")`,
        // }}
      />
      <Card className="mt-16 z-10 relative">
        <h2 className="font-proximanova-black text-left text-[25px] text-secondary uppercase mt-8 mb-5">
          Informations personnelles
        </h2>
        <div className="flex flex-col md:flex-row md:gap-5">
          {/* <input
            type="text"
            name={entries.lastname}
            onChange={(event) =>
              setUser({ ...user, lastname: event.target.value })
            }
            placeholder="Nom"
            className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary font-montserrat-regular"
          />
          <input
            type="text"
            name={entries.firstname}
            onChange={(event) =>
              setUser({ ...user, firstname: event.target.value })
            }
            placeholder="Prénom"
            className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary font-montserrat-regular"
          /> */}
        </div>
        <select
          className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary font-proximanova-regular"
          onChange={(event) =>
            setUser({ ...user, home_site: event.target.value })
          }
          defaultValue=""
          name={entries.home_site}
        >
          <option disabled value="">
            Site de rattachement
          </option>
          <option value="AXIAL">AXIAL</option>
          <option value="CLEAN SERVICE">CLEAN SERVICE</option>
          <option value="DIPAN">DIPAN</option>
          <option value="HERES">HERES</option>
          <option value="JEM">JEM</option>
          <option value="L'ECLAT D'ALSACE">L'ECLAT D'ALSACE</option>
          <option value="NETTEC">NETTEC</option>
          <option value="NILE">NILE</option>
          <option value="OPTINETT">OPTINETT</option>
          <option value="SEGI PROPRETE">SEGI PROPRETE</option>
          <option value="SEGI SERVICE">SEGI SERVICE</option>
          <option value="SP33">SP33</option>
          <option value="SP38">SP38</option>
          <option value="SP59">SP59</option>
          <option value="SP69">SP69</option>
          <option value="SP51">SP51</option>
          <option value="SP74">SP74</option>
          <option value="SP75">SP75</option>
          <option value="STEM SAS">STEM SAS</option>
          <option value="ES">ES</option>
          <option value="SURGARD">SURGARD</option>
        </select>

        {/* <div>
          <input
            type="checkbox"
            name="cgu"
            id="cgu"
            className="mr-2"
            onChange={(event) =>
              setUser({ ...user, rules: event.target.checked })
            }
            required
          />
          <label htmlFor="cgu" className="font-montserrat-regular">
            J'accepte le{" "}
            <Link to="/reglement" className="text-fourth font-montserrat-medium">
              règlement du jeu concours 
            </Link>
          </label>
        </div> */}

        <div className="flex justify-center mt-16">
          {isAwnserd && (
            <PrimaryButton
              onClick={handleClick}
              className={joinClasses(
                !isAwnserd && "!cursor-not-allowed pointer-events-none"
              )}
            >
              Continuer {">"}
            </PrimaryButton>
          )}
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-center md:bg-left"
        style={{
          backgroundImage: `url("/assets/questions/right.svg")`,
        }}
      />
    </div>
  );
}

import { Checkbox, PrimaryButton, Radio, Textarea } from "components/atoms";
import { QuestionTemplate } from "components/modecules";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import questions from "utils/questions";

export default function Questions({ show = false }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isAwnserd, setIsAwnserd] = useState(false);

  const [isAnswerDisplayed, setIsAnswerDisplayed] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    setIsAwnserd(false);
    setIsAnswerDisplayed(true);
    setIsFormSubmitted(false);
    window.scrollTo(0, 0);
  };

  const handleAwnser = (event) => {
    setIsAwnserd(true);
    window.scrollTo(0, document.body.scrollHeight);
    const value = event.target.value;
  };

  // pour afficher réponse + explication
  const handleClickAnswer = (event) => {
    event.preventDefault();
    setIsAnswerDisplayed(false);
    setIsFormSubmitted(true);
  };

  const getDescription = (type) => {
    switch (type) {
      case "checkbox":
        return "Plusieurs réponses possibles";
      case "radio":
        return "Une seule réponse possible";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (questions[currentQuestion].bonus) {
      setIsAwnserd(true);
    }
  }, [currentQuestion]);

  return (
    <div className={show ? "block" : "hidden"}>
      {questions.map((question, index) => (
        <QuestionTemplate
          question={question}
          active={index === currentQuestion}
          position={currentQuestion + 1}
          key={index}
        >
          <p className="mt-4 font-proximanova-regular text-[14px] text-secondary mb-5">
            {getDescription(question.type)}
          </p>
          <div className="text-secondary">
            {question.answers ? (
              question.answers?.map((answer, index) => {
                switch (question.type) {
                  case "checkbox":
                    return (
                      <div
                        key={index}
                        className={joinClasses(
                          isFormSubmitted
                            ? question.rightAnswers.some(
                                (rightAnswer) =>
                                  rightAnswer.answer === answer.label ||
                                  rightAnswer.answer === answer.value
                              )
                              ? "bg-green-200 pointer-events-none"
                              : "pointer-events-none bg-red-200"
                            : "",
                          "p-1 pb-2 m-1"
                        )}
                      >
                        <Checkbox
                          type={question.type}
                          name={question.name}
                          label={answer.label}
                          value={answer.value}
                          onChange={handleAwnser}
                          key={question.name + index}
                        />
                      </div>
                    );
                  case "radio":
                    return (
                      <div
                        key={index}
                        className={joinClasses(
                          isFormSubmitted
                            ? question.rightAnswers.some(
                                (rightAnswer) =>
                                  rightAnswer.answer === answer.label
                              )
                              ? "bg-green-200 pointer-events-none"
                              : "bg-red-200 pointer-events-none" // bg-red-200
                            : "",
                          "p-1 pb-2 m-1"
                        )}
                      >
                        <Radio
                          type={question.type}
                          name={question.name}
                          label={answer.label}
                          value={answer.value}
                          onChange={handleAwnser}
                          key={question.name + index}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              })
            ) : (
              <Textarea
                type={question.type}
                name={question.name}
                key={question.name + index}
                placeholder="Écrivez votre réponse ici"
              />
            )}
          </div>

          {/* afficher l'explication */}
          {!isAnswerDisplayed && (
            <div className="mt-4 mb-4 font-proximanova-regular text-[14px] text-secondary">
              <hr className="border-secondary mb-5" />
              <p className="uppercase font-proximanova-bold tracking-[0.3em] bg-secondary text-primary px-3 py-2 mb-4 w-fit">
                Commentaire
              </p>
              <p style={{ whiteSpace: "pre-line" }}>
                {isAnswerDisplayed ? "" : question.description}
              </p>
            </div>
          )}

          {/* Bouton pour afficher la réponse, si déjà appuyer affiche la question suivante et si c'est la fin, le bouton terminé */}
          {isAnswerDisplayed ? (
            <div className="flex md:justify-end items-center justify-center">
              <hr className="border-grey w-full mr-4"></hr>
              <PrimaryButton
                onClick={handleClickAnswer}
                className={joinClasses(
                  // "sm:translate-y-1/2 sm:translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal mt-4",
                  "whitespace-nowrap",
                  !isAwnserd &&
                    "bg-[#DCE892] !cursor-not-allowed pointer-events-none hidden whitespace-nowrap"
                )}
              >
                Vérifier {">"}
              </PrimaryButton>
            </div>
          ) : isAwnserd && index + 1 < questions.length ? (
            <div className="flex md:justify-end items-center justify-center">
              <hr className="border-grey w-full mr-4 hidden md:block"></hr>
              <PrimaryButton
                onClick={handleClick}
                className={joinClasses(
                  // "sm:translate-y-1/2 sm:translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal mt-4",
                  "whitespace-nowrap",
                  !isAwnserd &&
                    "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
                )}
              >
                Question suivante {">"}
              </PrimaryButton>
            </div>
          ) : (
            isAwnserd &&
            index + 1 === questions.length && (
              <div className="flex justify-end items-center">
                <PrimaryButton
                  type="submit"
                  className={joinClasses(
                    "translate-y-1/2 translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal",
                    !isAwnserd &&
                      "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
                  )}
                >
                  Terminer
                </PrimaryButton>
              </div>
            )
          )}
        </QuestionTemplate>
      ))}
    </div>
  );
}

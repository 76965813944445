import joinClasses from "helpers/joinClasses";

export function SubTitle({ children, className }) {
  return (
    <h3
      className={joinClasses(
        "font-proximanova-bold text-md text-black uppercase mt-8 mb-1",
        className
      )}
    >
      {children}
    </h3>
  );
}

import { Card, PrimaryButton, Title } from "components/atoms";

export default function Welcome({ onNext = () => {} }) {
  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <div className="flex justify-between w-full w-full">
      <div
        className="w-full bg-no-repeat bg-center"
        // style={{
        //   backgroundImage: `url("/assets/questions/1-2.svg")`,
        // }}
      />
      <Card className="mt-16 z-10 flex flex-col justify-around relative">
        {/* TITLE BLOCK */}
        <div className="absolute -top-14 left-20 z-10">
          <img src="/assets/interrogation.svg" />
        </div>
        <div className="relative">
          <Title className="font-proximanova-black text-center z-10 border-b-[10px] border-black w-full">
            Quiz
          </Title>
        </div>

        <h2 className="font-proximanova-black text-center text-[22px] mt-8 mb-5">
          Handicap et Emploi
        </h2>

        <p className="text-black text-center font-proximanova-regular text-[17px] leading-[24px]">
          Pour cette Semaine Européenne de l'Emploi des Personnes Handicapées
          (SEEPH) nous vous invitons à explorer les enjeux et les notions clés
          du handicap au travail
        </p>

        <p className="text-black text-center font-proximanova-regular text-[17px] leading-[24px] mt-5">
          À vous de jouer 🚀
        </p>
        <div className="flex justify-center mt-10">
          <PrimaryButton onClick={handleClick}>Commencer</PrimaryButton>
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-center"
        // style={{
        //   backgroundImage: `url("/assets/questions/1-1.svg")`,
        // }}
      />
    </div>
  );
}

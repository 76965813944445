import joinClasses from "helpers/joinClasses";
import { Link } from "react-router-dom";

export function Footer({ dark = false }) {
  return (
    <>
      <footer
        className={joinClasses(
          "font-sans max-w-[90%] md:max-w-[1134px] border-t mx-auto mt-16 text-xs md:text-[14px] transition-all duration-300 ease-in-out px-4 xl:px-0 absolute bottom-1 md:bottom-5 left-0 right-0",
          dark ? "border-black text-black" : "border-white text-white"
        )}
      >
        <div className="flex items-center md:justify-between my-3 flex-col md:flex-row gap-2 ">
          <p>© 2023 Yumaincap x Stem Groupe</p>

          <ul className="flex justify-center gap-16">
            <li className="text-center">
              <Link to="/mentions-legales">Mentions légales</Link>
            </li>
          </ul>
        </div>
      </footer>
      <div className="h-[150px] md:h-[80px] " />
    </>
  );
}

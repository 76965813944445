const questions = [
  {
    id: 1,
    name: "entry.570372224",
    type: "radio",
    title: "Quelle est la définition du handicap ?",
    selectMax: 1,
    images: { left: "1.png", right: "right.svg" },
    answers: [
      {
        label:
          "Une limitation d'activité ou une restriction de participation à la vie en société qui résulte d'une altération d'une ou plusieurs fonctions physiques, sensorielles, mentales ou cognitives.",
        value:
          "Une limitation d'activité ou une restriction de participation à la vie en société qui résulte d'une altération d'une ou plusieurs fonctions physiques, sensorielles, mentales ou cognitives.",
      },
      {
        label: "Une maladie ou un traumatisme qui rend une personne invalide.",
        value: "Une maladie ou un traumatisme qui rend une personne invalide.",
      },
      {
        label:
          "Une situation de désavantage social ou économique résultant d'une limitation d'activité ou d'une restriction de participation à la vie en société.",
        value:
          "Une situation de désavantage social ou économique résultant d'une limitation d'activité ou d'une restriction de participation à la vie en société.",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Une limitation d'activité ou une restriction de participation à la vie en société qui résulte d'une altération d'une ou plusieurs fonctions physiques, sensorielles, mentales ou cognitives.",
      },
    ],
    description:
      "La limitation ou restriction doit être durable (supérieure à un an) ou définitive. Dès lors qu'une situation de santé influence sur le travail, la reconnaissance du statut de travailleur handicapé peut être attribuée de manière ponctuelle ou définitive et ainsi permettre au salarié d’avoir les aides adaptées.",
  },
  {
    id: 2,
    name: "entry.79613802",
    type: "radio",
    title: "La plupart des handicaps sont acquis",
    selectMax: 1,
    images: { left: "2.png", right: "right.svg" },
    answers: [
      {
        label: "A la naissance",
        value: "A la naissance",
      },
      {
        label: "Suite à des accidents du travail",
        value: "Suite à des accidents du travail",
      },
      {
        label: "Après 16 ans",
        value: "Après 16 ans",
      },
    ],
    rightAnswers: [
      {
        answer: "Après 16 ans",
      },
    ],
    description:
      "Le vieillissement et les maladies non professionnelles survenant au cours de la vie constituent la première cause de handicap (46%), les accidents du travail et les maladies professionnelles sont à l'origine de 17% des handicaps. Seulement 15% des handicaps sont acquis à la naissance ou dans l'enfance.",
  },
  {
    id: 3,
    name: "entry.2106493221",
    type: "radio",
    title: "Quel est le pourcentage de personnes handicapées en France ?",
    selectMax: 1,
    images: { left: "3.png", right: "right.svg" },
    answers: [
      {
        label: "10%",
        value: "10%",
      },
      {
        label: "20%",
        value: "20%",
      },
      {
        label: "30%",
        value: "30%",
      },
    ],
    rightAnswers: [
      {
        answer: "10%",
      },
    ],
    description:
      "Quelque 7,6 millions de personnes de plus de 15 ans, soit 14,1 % de la population française, sont en situation de handicap, selon une étude publiée le 2 février 2023 par la Drees, le service statistique des ministères sociaux.",
  },
  {
    id: 4,
    name: "entry.671473983",
    type: "checkbox",
    title: "Quels sont les différents types de handicaps ?",
    selectMax: 1,
    images: { left: "4.png", right: "right.svg" },
    answers: [
      {
        label: "Handicap physique",
        value: "Handicap physique",
      },
      {
        label: "Handicap visuel",
        value: "Handicap visuel",
      },
      {
        label: "Handicap auditif",
        value: "Handicap auditif",
      },
      {
        label: "Handicap mental ou intellectuel",
        value: "Handicap mental ou intellectuel",
      },
      {
        label: "Handicap cognitif",
        value: "Handicap cognitif",
      },
      {
        label: "Handicap psychique",
        value: "Handicap psychique",
      },
      {
        label: "Les maladies invalidantes",
        value: "Les maladies invalidantes",
      },
    ],
    rightAnswers: [
      {
        answer: "Handicap visuel",
      },
      {
        answer: "Handicap auditif",
      },
      {
        answer: "Handicap mental ou intellectuel",
      },
      {
        answer: "Handicap cognitif",
      },
      {
        answer: "Handicap psychique",
      },
      {
        answer: "Les maladies invalidantes",
      },
    ],
    description:
      "La loi française du 11 février 2005 pour l’égalité des droits et des chances précise les catégories de handicap, à savoir : mental (impliquant notamment une déficience intellectuel comme la trisomie 21), moteur ( avec une limitation physique comme le boitement, paralysie d’un membre, tétraplégie..), sensoriel (qui atteignent la vue ou l’audition), cognitif ( avec une altération des fonctions cognitives comme pour les TDAH, trouble dys…), psychique (qui regroupe les pathologies qui atteignent le psychisme comme la dépression, la bipolarité, TOC), les maladies invalidantes (qui peuvent atteindre les organes vitaux comme le cancer, diabète, endométriose, douleurs articulaires…)",
  },
  {
    id: 5,
    name: "entry.1779121535",
    type: "radio",
    title: "Quelle est la proportion des handicaps non visibles ?",
    selectMax: 1,
    images: { left: "5.png", right: "right.svg" },
    answers: [
      {
        label: "20%",
        value: "20%",
      },
      {
        label: "50%",
        value: "50%",
      },
      {
        label: "80%",
        value: "80%",
      },
    ],
    rightAnswers: [
      {
        answer: "80%",
      },
    ],
    description:
      "Dans 8 cas sur 10, le handicap est invisible.\nNe concluez donc pas trop vite qu’il n’y a pas de personne en situation de handicap dans votre équipe ou qu’il est impossible de recruter une personne en situation de handicap dans votre structure !",
  },
  {
    id: 6,
    name: "entry.322132351",
    type: "radio",
    title: "Qu'est-ce que la RQTH ?",
    selectMax: 1,
    images: { left: "1.png", right: "right.svg" },
    answers: [
      {
        label: "Un dispositif d'accompagnement pour les personnes handicapées.",
        value: "Un dispositif d'accompagnement pour les personnes handicapées.",
      },
      {
        label: "Une allocation financière accordée aux personnes handicapées.",
        value: "Une allocation financière accordée aux personnes handicapées.",
      },
      {
        label:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
        value:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
      },
    ],
    description:
      "La RQTH est un statut administratif. Il ne s'agit pas non plus d'une aide financière ou d'un accompagnement en tant que tel. Cependant, la RQTH permet d'accéder à des financements d'aménagement du poste de travail et à un accompagnement spécifique à l'emploi.",
  },
  {
    id: 7,
    name: "entry.1014509151",
    type: "radio",
    title: "Qui peut être reconnu Travailleur Handicapé ?",
    selectMax: 1,
    images: { left: "2.png", right: "right.svg" },
    answers: [
      {
        label: "Uniquement les personnes ayant un handicap physique.",
        value: "Uniquement les personnes ayant un handicap physique.",
      },
      {
        label:
          "Uniquement les personnes qui ont besoin d'un aménagement de poste.",
        value:
          "Uniquement les personnes qui ont besoin d'un aménagement de poste.",
      },
      {
        label:
          "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
        value:
          "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
      },
    ],
    description:
      "La RQTH sert surtout à justifier qu'une situation de santé peut ou impacte directement le quotidien de travail, et peut jouer par exemple sur l'assiduité. L'aménagement n'est nécessaire que dans 15 % des cas.",
  },
  {
    id: 8,
    name: "entry.1213557657",
    type: "radio",
    title:
      "Une personne sur deux risque d’être confrontée à une situation de handicap au cours de sa vie professionnelle ?",
    selectMax: 1,
    images: { left: "3.png", right: "right.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [
      {
        answer: "Vrai",
      },
    ],
    description:
      "Une personne sur deux sera confrontée à une situation de handicap dans sa vie active. Le vieillissement des salariés, l’allongement de la durée du travail en sont à l’origine.",
  },
  {
    id: 9,
    name: "entry.1989222612",
    type: "radio",
    title:
      "En transmettant ma RQTH, mon employeur sera informé de la nature de ma pathologie",
    selectMax: 1,
    images: { left: "4.png", right: "right.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [
      {
        answer: "Faux",
      },
    ],
    description:
      "Faux, la nature de la pathologie n'apparaît pas sur la notification de la RQTH, et le salarié n'a aucune obligation de la dévoiler, sauf s'il le souhaite.",
  },
  {
    id: 10,
    name: "entry.2123233553",
    type: "radio",
    title:
      "Parmi les personnes souffrant de handicap moteur, combien se déplacent en fauteuil roulant ?",
    selectMax: 1,
    images: { left: "5.png", right: "right.svg" },
    answers: [
      {
        label: "73%",
        value: "73%",
      },
      {
        label: "12%",
        value: "12%",
      },
      {
        label: "Moins de 5%",
        value: "Moins de 5%",
      },
    ],
    rightAnswers: [
      {
        answer: "Moins de 5%",
      },
    ],
    description:
      "En France, plus de 8 millions de personnes sont touchées par une déficience motrice, de la plus légère (rhumatisme, arthrose) à la plus lourde (hémiplégie, paraplégie, tétraplégie). Seules 5% de ces personnes utilisent un fauteuil.",
  },
  {
    id: 11,
    name: "entry.257184032",
    type: "checkbox",
    title:
      "Parmi les personnes suivantes, lesquelles peuvent bénéficier de la RQTH ?",
    selectMax: 1,
    images: { left: "1.png", right: "right.svg" },
    answers: [
      {
        label: "Un maître-nageur allergique au chlore",
        value: "Un maître-nageur allergique au chlore",
      },
      {
        label: "Une assistante administrative souffrant de diabète",
        value: "Une assistante administrative souffrant de diabète",
      },
      {
        label: "Un ingénieur malentendant",
        value: "Un ingénieur malentendant",
      },
    ],
    rightAnswers: [
      {
        answer: "Un maître-nageur allergique au chlore",
      },
      {
        answer: "Une assistante administrative souffrant de diabète",
      },
      {
        answer: "Un ingénieur malentendant",
      },
    ],
    description:
      "Toutes ces personnes sont susceptibles de souffrir de déficiences ayant une incidence sur leur travail. Selon l'importance de cette déficience, elles bénéficieront ou non du statut de Travailleur Handicapé.",
  },
  {
    id: 12,
    name: "entry.2060065385",
    type: "radio",
    title:
      "Quel est le taux légal des travailleurs handicapés dans les entreprises privées de plus de 20 salariés ?",
    selectMax: 1,
    images: { left: "2.png", right: "right.svg" },
    answers: [
      {
        label: "3%",
        value: "3%",
      },
      {
        label: "6%",
        value: "6%",
      },
      {
        label: "9%",
        value: "9%",
      },
    ],
    rightAnswers: [
      {
        answer: "6%",
      },
    ],
    description:
      "Toutes les entreprises de plus de 20 salariés doivent avoir dans leur effectif au moins 6% de salariés bénéficiaires de l’obligation d’emploi de travailleurs handicapés.",
  },
  {
    id: 13,
    name: "entry.1723030807",
    type: "radio",
    title:
      "Mon entreprise, qui emploie plus de 20 personnes, se trouve au 3ème étage sans ascenseur :",
    selectMax: 1,
    images: { left: "3.png", right: "right.svg" },
    answers: [
      {
        label: "Je suis dispensé de recruter des Travailleurs Handicapés.",
        value: "Je suis dispensé de recruter des Travailleurs Handicapés.",
      },
      {
        label: "La loi m'oblige à effectuer des travaux d'accessibilité.",
        value: "La loi m'oblige à effectuer des travaux d'accessibilité.",
      },
      {
        label:
          "Je dois répondre à la même obligation d'emploi que n'importe quelle entreprise de plus de 20 salariés.",
        value:
          "Je dois répondre à la même obligation d'emploi que n'importe quelle entreprise de plus de 20 salariés.",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Je dois répondre à la même obligation d'emploi que n'importe quelle entreprise de plus de 20 salariés.",
      },
    ],
    description: "Il n'existe pas de dispense à la loi du 11 février 2005.",
  },
  {
    id: 14,
    name: "entry.761406308",
    type: "radio",
    title:
      "Parmi les personnes suivantes, lesquelles ne peuvent en aucun cas exercer leur métier ?",
    selectMax: 1,
    images: { left: "4.png", right: "right.svg" },
    answers: [
      {
        label: "Un standardiste malentendant",
        value: "Un standardiste malentendant",
      },
      {
        label: "Un conducteur de car uni-jambiste",
        value: "Un conducteur de car uni-jambiste",
      },
      {
        label: "Un professeur malvoyant",
        value: "Un professeur malvoyant",
      },
      {
        label: "Aucune",
        value: "Aucune",
      },
    ],
    rightAnswers: [
      {
        answer: "Aucune",
      },
    ],
    description:
      "Aucune, moyennant des aménagements techniques et/ou des aides humaines, ces trois personnes peuvent occuper leur poste.",
  },
  {
    id: 15,
    name: "entry.1093844621",
    type: "radio",
    title: "Quel est l'utilité d'être reconnu Travailleur Handicapé ?",
    selectMax: 1,
    images: { left: "5.png", right: "right.svg" },
    answers: [
      {
        label: "Obtenir des places de stationnement réservées",
        value: "Obtenir des places de stationnement réservées",
      },
      {
        label:
          "Bénéficier de droits, d'aides financières et de services pour trouver ou conserver un emploi et évoluer dans l'entreprise",
        value:
          "Bénéficier de droits, d'aides financières et de services pour trouver ou conserver un emploi et évoluer dans l'entreprise",
      },
      {
        label: "Aucun intérêt",
        value: "Aucun intérêt",
      },
    ],
    rightAnswers: [
      {
        answer:
          "Bénéficier de droits, d'aides financières et de services pour trouver ou conserver un emploi et évoluer dans l'entreprise",
      },
    ],
    description:
      "Les bénéfices de la RQTH sont divers : obtenir un aménagement de poste spécifique, c’est sécuriser l'avenir en cas d'évolution défavorable de sa situation de handicap, c’est aussi bénéficier d'aides en dehors de l'entreprise et aide spécifique dans votre vie privée ou encore des mesures de protection pour pouvoir partir plus tôt à la retraite (sous certaines conditions spécifiques).",
  },
  {
    id: 16,
    name: "entry.2142197449",
    type: "radio",
    title:
      "Quel est l'un des obstacles à l'intégration d'une personne handicapée dans l'entreprise ?",
    selectMax: 1,
    images: { left: "1.png", right: "right.svg" },
    answers: [
      {
        label: "Le trop grand nombre de personnes handicapées",
        value: "Le trop grand nombre de personnes handicapées",
      },
      {
        label: "Les idées reçues sur le handicap",
        value: "Les idées reçues sur le handicap",
      },
      {
        label: "Le manque d'humour des personnes handicapées",
        value: "Le manque d'humour des personnes handicapées",
      },
    ],
    rightAnswers: [
      {
        answer: "Les idées reçues sur le handicap",
      },
    ],
    description:
      "Les stéréotypes et préjugés autour du handicap en entreprise sont l’un des principaux freins à l’embauche des personnes en situation de handicap. Il est donc important de communiquer, partager, sensibiliser sur le sujet pour lever les idées reçues et avancer collectivement.",
  },
  {
    id: 17,
    name: "entry.517800929",
    type: "radio",
    title:
      "Si je déclare ma RQTH à mon entreprise, tout le monde sera au courant de ma situation",
    selectMax: 1,
    images: { left: "2.png", right: "right.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
    rightAnswers: [
      {
        answer: "Faux",
      },
    ],
    description:
      "La RQTH est un document personnel et confidentiel, c’est le salarié qui choisit d’en faire part, s’il le souhaite, à son employeur actuel ou futur. De même, la hiérarchie et les collègues n’ont pas à être informés de cette situation, sauf si le salarié, en accord avec l’employeur, souhaite communiquer sur ce sujet (pour mieux prendre en compte son handicap).",
  },
];

export default questions;

import { Card, SubTitle } from "components/atoms";
import { Template } from "components/modecules";

export default function SuccessPage() {
  return (
    <Template>
      <Card className="m-auto flex flex-col justify-center mt-10">
        <h2 className="font-proximanova-black text-center text-[25px] text-secondary uppercase mt-8 mb-5">
          Votre réponse a été enregistrée.
        </h2>
        <p className="text-center mt-5 font-proximanova-regular">
          Merci d’avoir participé au quiz !
        </p>
      </Card>
    </Template>
  );
}
